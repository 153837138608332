import request from '@/utils/request'

/* *获取最新可用的已生效的APP版本* */
export function GetEnableLatestAppVersion(data) {
  return request({
    url: '/v1/get_app_version',
    method: 'post',
    data
  })
}
